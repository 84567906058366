import React, { Component } from 'react';
import './App.css';
import Item from './item.js';
import linkedin from './linkedin.png'
import github from './github.png'
import { Fade, Slide } from 'react-awesome-reveal';

class App extends Component {
  render() {
  var googleDescription = "Participated in the Engineering Residency program which involved" +
  	" training and experience on two software engineering teams. I created search features " +
  	"in javascript as a part of the Learning and Education team and Launched a new feature " +
  	"for comments in Java as a member of the docs collaboration team" ;
  var collinsDescription = "Using the X-Plane SDK, I worked with a team of three other students" +
    " to put Collins' Avionics software into X-Plane cockpits. This saves Collins Aerospace " + 
    "the time and money of building a physical cockpit as a testing environment.";
  var andCultureDescription = "Worked on stacksapp.io using Typescript and React. Created " + 
    "components, tests using Jest, rich text features using Draft.js, and a Slack app integration.";
  var noiseHubDescription = "Helped develop a mobile application to help people communicate " + 
    "with others using music. Used Swift in Xcode, designed app assets, and worked with a team" + 
    " of three other developers. Won the JD Brown Center’s 2015 Elevator Pitch Competition and" + 
    " was mentioned in Tech Crunch in 2018."
      return (
      <div className="My Site">
        <header className="Site-header">
          <div className="iconBar">
            <a href="https://www.linkedin.com/in/alyssa-mcdevitt-23b89294/" target = "_blank"> 
              <img src={linkedin} alt="Linkedin" title="My Linkedin" className="icon"/>
            </a>
            <a href="http://github.com/amcdevitt97" target = "_blank">
              <img src={github} alt="Github" className="icon" title="My Github"/>
            </a>
           
          </div>
          <Fade triggerOnce="true">
          <div className="Welcome fade-in">
          <h1>Hey, I'm </h1> <div className="myName">Alyssa McDevitt</div>
          </div>
          </Fade>
          <div className="Description fade-in">
            <p>
            I'm a software engineer and alumna of York College of Pennsylvania. Lover of 
            all things tech and trying to learn more about this great big world. Read more
             about me below.
            </p>
            <hr/>
          </div>
          
          <div className="resume">
            
              <Item delay="1000" className="fade-in one" title="Google - July 2020-Current" 
              role="Software Engineering Resident" description={googleDescription} />
            
           
              <Item delay="1300" className="fade-in one" title="Collins Aerospace - Summer 2019"
              role="SEPP Software Engineering Intern" description={collinsDescription}/>
           
              <Item delay="1600" className="fade-in two" title="AndCulture - Summer 2018" 
              role="Software Engineering Intern" description={andCultureDescription} />
         
              <Item delay="1900" className="fade-in four" title="NoiseHub - 2015-2017" 
              role="Cofounder" description={noiseHubDescription}/>

            
          </div>
        </header>
      </div>
    );
  }
}

export default App;
