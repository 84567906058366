import './App.css';
import React from 'react';
import { Fade, Slide } from 'react-awesome-reveal';

class Item extends React.Component {
    render() {
      return( 
        <Fade direction="bottom" delay={this.props.delay} triggerOnce="true">
        <div className="resumeItem">
            <p className="itemTitle">{this.props.title}</p>
            <p className="itemRole">{this.props.role}</p>
            <span className="itemDescription">{this.props.description}</span>
            <a className="itemLink" href={this.props.link} target="_blank">{this.props.linkText }</a>
        </div>
        </Fade>
        );
    }
  }
export default Item;